import React, {Component} from "react";


import withAuthorization from "./withAuthorization";
import {db} from "../firebase";

import "./CustomStyling.css";

function getDim(strIn) {
  const width = { windowWidth: window.innerWidth };
  const height = { windowWidth: window.innerHeight };
  const widthVal = width.windowWidth;
  const heightVal = height.windowWidth;
  console.log(heightVal);
  if (strIn === "width") {
    if (widthVal >= 600) {
    return widthVal;
    } else if (widthVal < 600) {
      return widthVal
    }
  } else if (strIn === "height") {
    if (heightVal >= 600) {
      return heightVal;
      } else if (heightVal < 600) {
        return heightVal;
      }
  }
}
getDim();

class LearningPage extends Component {

  componentDidMount() {
    const { loggedUser } = this.props;
    db.doGetAnUnser(loggedUser.uid).then((res) => {
      this.setState({
        username: res.val().username,
        loading: false,
      });
    });
  }

  render() {
    return (
      <div className="divLeftMobile">
        <div className="testing">
          <Iframe iframe={iframe} />
        </div>
      </div>
    );
  }
}

const iframe = `<iframe src="https://coda.io/embed/83ot2xaWQi/_suHMC?viewMode=embedplay" width=${getDim('width')} height=${getDim('height')} style="max-width: 100%;" allow="fullscreen"></iframe>`;
function Iframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
    />
  );
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(LearningPage); //grants authorization to open endpoint if an user is signed in
