import MenuItem from "@material-ui/core/MenuItem";
import {NavLink} from "react-router-dom";

import React, {useEffect, useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import "firebase/auth";
import firebase from "firebase/app";
import Grid from "@material-ui/core/Grid";
import basilLogo from '.././files/basil_logo.png'


const headersDataLoggedIn = [
    {
        label: "Projects",
        href: "/projects",
    },
    {
        label: "Learning",
        href: "/learning",
    },
    {
        label: "Requests",
        href: "/requests",
    },
    {
        label: "Account",
        href: "/account",
    }
];

const headersDataLoggedOut = [
    // {
    //     label: "Log In",
    //     href: "/signin",
    // },
    // {
    //     label: "Sign Up",
    //     href: "/signup",
    // },
];


const HeaderNav = (props) => {
    const classes = useStyles();

    const [state, setState] = useState({
        mobileView: false,
        drawerOpen: false,
    });

    const {mobileView, drawerOpen} = state;

    const [dataIn, setDataIn] = useState([])

    useEffect(() => {
        setDataIn(props.dataInProp)
    }, [])

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 750
                ? setState((prevState) => ({...prevState, mobileView: true}))
                : setState((prevState) => ({...prevState, mobileView: false}));
        };
        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());
    }, []);

    const DisplayDesktop = (props) => {
        return (
            <div>
                <AppBar className={classes.header}>
                    <Toolbar className={classes.toolbar}>
                        <Grid item>
                            <a href="http://basillabs.org/">
                            {Logo}</a>
                        </Grid>
                        <Grid item xs/>
                        <Grid className={classes.linkGrid}>
                            <GetMenuButtons/>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Toolbar/>
            </div>
        );
    };

    const DisplayMobile = () => {
        const handleDrawerOpen = () =>
            setState((prevState) => ({...prevState, drawerOpen: true}));
        const handleDrawerClose = () =>
            setState((prevState) => ({...prevState, drawerOpen: false}));
        console.log(dataIn)
        return (
            <div>
                <AppBar className={classes.header}>
                    <Toolbar>
                        <IconButton
                            {...{
                                edge: "start",
                                color: "inherit",
                                "aria-label": "menu",
                                "aria-haspopup": "true",
                                onClick: handleDrawerOpen,
                            }}
                        >
                            <MenuIcon/>
                        </IconButton>

                        <Drawer
                            {...{
                                anchor: "left",
                                open: drawerOpen,
                                onClose: handleDrawerClose,
                            }}
                        >
                            <div className={classes.drawer}>
                                {dataIn.map((item) => (
                                        <NavLink className={classes.drawerLink} activeClassName={classes.active}
                                            // component={RouterLink}
                                            to={item.href}
                                            color={"inherit"}
                                            style={{textDecoration: "none"}}
                                            key={item.label}
                                            onClick={handleDrawerClose}
                                        >
                                            {/*<p>{item.href}</p>*/}
                                            <MenuItem className={classes.navItem}>{item.label}</MenuItem>
                                        </NavLink>
                                    )
                                )}
                            </div>

                        </Drawer>
                        <a href="http://basillabs.org/">
                        <div>{Logo}</div></a>
                    </Toolbar>
                </AppBar>
                <Toolbar/>
            </div>
        );
    };

    const Logo = (
        // <Link to={'/signin'} color={'inherit'} style={{textDecoration: "none"}}>
        //     <Typography variant="h6" component="h1" className={classes.logo}>
        //         BasilCX
        //     </Typography>
        <img src={basilLogo} className={classes.logoSizing}/>
        // </Link>
    );

    const GetMenuButtons = () => {
        return dataIn.map(({label, href}) => {
            return (
                // <NavLink
                //     {...{
                //         key: label,
                //         to: href,
                //         component: RouterLink,
                //         className: classes.menuButton,
                //         activeClassName: classes.active
                //     }}>
                //     {label}
                // </NavLink>

            <NavLink className={classes.drawerLink} activeClassName={classes.active}
                // component={RouterLink}
                     to={href}
                     color={"inherit"}
                     style={{textDecoration: "none"}}
                     key={label}
            >
                {/*<p>{item.href}</p>*/}
                <MenuItem className={classes.navItem}>{label}</MenuItem>
            </NavLink>
            );
        });
    };
    return (
        <header>
            {mobileView ? DisplayMobile() : DisplayDesktop()}
        </header>
    );
}

const NavLoggedIn = () => {
    return (
        <HeaderNav dataInProp={headersDataLoggedIn}/>
    )
}

const NavLoggedOut = () => {
    return (
        <HeaderNav dataInProp={headersDataLoggedOut}/>
    )
}

const Navigation = () => {
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);

    firebase.auth().onAuthStateChanged(function (user) {
        setIsLoggedIn(!!user);
    });
    return (
        <div>
            {isLoggedIn ? (<NavLoggedIn/>) : (
                <NavLoggedOut/>)}
        </div>
    )
};

// <HeaderNav dataInProp={headersDataLoggedOut}/>
export default Navigation


const useStyles = makeStyles(() => ({
    header: {
        backgroundColor: "#1b2430",
        marginBottom: "40px",
        // backgroundColor: "#2A2A39",
        // paddingRight: "79px",
        // paddingLeft: "118px",
        paddingRight: "0px",
        paddingLeft: "0px",
        boxShadow: "none",
        "@media (max-width: 900px)": {
            paddingLeft: 0,
        }
    },
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
    logo: {
        fontFamily: "Work Sans, sans-serif",
        fontWeight: 600,
        color: "#FFFEFE",
        textAlign: "left",
        flexGrow: 1,
    },
    menuButton: {
        fontFamily: "Roboto, sans-serif",
        fontWeight: 400,
        // fontWeight: 550,
        size: "18px",
        marginLeft: "38px",
        color: "#b6c0cb",
        '&:hover': {
            color: "white",
        },
        textTransform: "none",
        textDecoration: "none"
    },
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        minHeight: "40px",
        maxWidth: "800px",
        alignSelf: "center"
    },
    drawer: {
        padding: "20px 30px",
        backgroundColor: "black",
        height: "100%"
    },
    drawerLink: {
        color: "#b6c0cb",
        '&:hover': {
            color: "white",
        },
    },
    navItem: {
        fontSize: 12,
        padding: "0px 18px"
    },
    active: {
        color: "#17B81C",
        '&:hover': {
            color: "#17B81C",
        },
    },
    logoSizing: {
        height: 17,
        marginTop: "-5px"
    },
    buttonNavColor: {
        color: "white"
    },
    linkGrid: {
        display:"flex",
        direction:"row",
    }

}));
