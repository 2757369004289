import React from "react";
import {Button} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import {auth} from "../firebase";

const useStyles = makeStyles({
    button: {
        background: '#17B81C',
        border: 0,
        borderRadius: 5,
        boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',
        color: 'white',
        height: 36,
        padding: '0 15px',
        '&:hover': {
            background: '#00d107',
            boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',
        },
    },
});

const SignOutButton = () => {
    const classes = useStyles();
    return (
    <Button className={classes.button} variant="contained" onClick={auth.doSignOut
    }>
        Sign Out
    </Button>
    )
}

export default SignOutButton;
