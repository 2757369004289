import React from "react";
import {BrowserRouter, Route} from "react-router-dom";
import "./App.css";
import "@fontsource/mukta"
import * as routes from "../constants/routes";

//nav stuff
import Navigation from "./Navigation";
import LandingPage from "./Landing";
import SignUpPage from "./SignUp";
import SignInPage from "./SignIn";
import PasswordForgetPage from "./PasswordForget";
import ProjectsPage from "./Projects";
import LearningPage from "./Learning";
import RequestsPage from "./Requests";
import AccountPage from "./Account";

import withAuthentication from "./withAuthentication";

const App = () => {
      return (
          <BrowserRouter>
                <Navigation/>
                <Route exact path="/" component={ProjectsPage} />
                {/* <Route exact path={routes.LANDING} component={LandingPage}/> */}
                <Route exact path={routes.SIGN_UP} component={SignUpPage}/>
                <Route exact path={routes.SIGN_IN} component={SignInPage}/>
                <Route
                    exact
                    path={routes.PASSWORD_FORGET}
                    component={PasswordForgetPage}
                />
                <Route exact path={routes.PROJECTS} component={ProjectsPage}/>
                <Route exact path={routes.LEARNING} component={LearningPage}/>
                <Route exact path={routes.REQUESTS} component={RequestsPage}/>
                <Route exact path={routes.ACCOUNT} component={AccountPage}/>
          </BrowserRouter>
      )
};


export default withAuthentication(App); //using HoC to handle session
