import React, {Component} from "react";
import {Link} from "react-router-dom";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import './CustomStyling.css';

import {auth} from "../firebase";
import * as routes from "../constants/routes";
import AuthUserContext from "./AuthUserContext";

//it resets your password. It doesn’t matter if you are authenticated or not
const PasswordForgetPage = () => (
    <div className="div-flex">
        <div>
            <h1 className="centered">Forget Password</h1>
            <PasswordForgetForm/>
        </div>
    </div>
);

const byPropKey = (propertyName, value) => () => ({
    [propertyName]: value
});

//################### PasswordForget Form ###################
const INITIAL_STATE = {
    email: "",
    error: null,
    showingAlert: false
};

class PasswordForgetForm extends Component {
    state = {...INITIAL_STATE};

    sentAlert = () => {
        alert("A link to reset your password has been sent to your email!");
    }

    onSubmit = event => {
        const {email} = this.state;

        auth
            .doPasswordReset(email)
            .then(() => {
                this.setState({...INITIAL_STATE});
                this.sentAlert();
            })
            .catch(error => {
                this.setState(byPropKey("error", error));
                this.timer(); //show alert message for some seconds
            });

        event.preventDefault();
    };

    timer = () => {
        this.setState({
            showingAlert: true
        });

        setTimeout(() => {
            this.setState({
                showingAlert: false
            });
        }, 4000);
    };


    render() {
        const {email, error, showingAlert} = this.state;
        const isInvalid = email === "";


        return (
            <div >
                {showingAlert && (
                    <div style={{fontWeight: 400,
                        textTransform: 'uppercase',
                        letterSpacing: '.05em',
                        fontSize: '1rem',
                        textAlign: "center",
                        paddingTop: '10px',
                        color: 'red'
                    }} onLoad={this.timer}>
                        {error.message}
                    </div>
                )}

                <form onSubmit={this.onSubmit}>
                    {/* <h4 for="exampleEmail">Email</h4> */}
                    <AuthUserContext.Consumer>
                        {authUser => (
                            <TextField
                                type="email"
                                name="email"
                                id="exampleEmail"
                                // label={"E-mail Address"}
                                placeholder={authUser.email}
                                fullWidth
                                value={email}
                                onChange={event =>
                                    this.setState(byPropKey("email", event.target.value))
                                }
                            />
                        )}</AuthUserContext.Consumer>

                    <div className="text-center">
                        <Button variant="contained" disabled={isInvalid} type="submit" style={{
                            marginTop: 30,
                            boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',
                            border: 0,
                            borderRadius: 5,
                            height: 36,
                            padding: '0 10px',
                            }}>
                            Reset My Password
                        </Button>
                    </div>
                </form>
            </div>
        );
    }
}

//################### PasswordForget Link ###################
const PasswordForgetLink = () => (
    <p>
        <Link to={routes.PASSWORD_FORGET}>Forgot Password?</Link>
    </p>
);

export default PasswordForgetPage;

export {PasswordForgetForm, PasswordForgetLink};
