import React from "react";
import SignOutButton from "./SignOut";
import Card from '@material-ui/core/Card'

import { PasswordForgetForm } from "./PasswordForgetLoggedIn";
import withAuthorization from "./withAuthorization";
import {makeStyles} from "@material-ui/core/styles";
// import backImage from "./train.jpg";

const useStyles = makeStyles({
    root: {
        // backgroundImage: `url(${backImage})`,
        backgroundSize: "cover",
        // height: "100vh",
        // width: "100%",
        // position: "fixed",
        backgroundColor: "white",
        textAlign: "center"
    },
    boxInner: {
        padding: 20,
    },
    padTop: {
        marginTop: 300,
    },
    titleContainer: {
        marginTop: 20,
    },
    largeTitle: {
        color: "white",
        textAlign: "center",
        fontSize: '24px',
        fontWeight: 300,
    },
    smallTitle: {
        fontWeight: 400,
        textTransform: 'uppercase',
        letterSpacing: '.05em',
        fontSize: '1rem',
        textAlign: "center",
        color: "#17B81C",
    },
    button: {
        background: '#17B81C',
        border: 0,
        borderRadius: 5,
        boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',
        color: 'white',
        height: 36,
        padding: '0 15px',
        '&:hover': {
            background: '#00d107',
            boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',
        },
    },
});

const AccountPage = () => {
    const classes = useStyles();
    //authUser is passed down via Context API (It is set at withAuthentication.js file)
    return (
        <div style={{maxWidth:800, margin: "auto"}}>
        <div className={classes.root} style={{padding: '1em', paddingTop: "5%", textAlign:"-webkit-center"}}>
            <Card style={{padding: "20px 8% 30px 8%"}}>
                <h5 className="centered" style={{marginBottom: 10}}>
                    Change or Reset Your Password
                </h5>
                <p style={{fontFamily:"system-ui", marginBottom:20}}>Type in your email below.</p>
                <PasswordForgetForm/>
            </Card>
            <div style={{paddingTop: "5em", textAlign: "center"}}>
                <SignOutButton/>
            </div>
        </div>
        </div>
    )
};

const authCondition = authUser =>
  !!authUser; //true and false

export default withAuthorization(authCondition)(AccountPage);
