import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

//this config is being used for both development and production environment. Though, it is a best practice creating a second project and have two configs: one for production (prodConfig) and another for development (devConfig), so you choose the config based on the environment.

// const config = {
//   apiKey: "AIzaSyAcOIzZPhgquE2g4NkrJovuIucSkMzYupI",
//   authDomain: "react-firebase-authentic-b43cd.firebaseapp.com",
//   databaseURL: "https://react-firebase-authentic-b43cd.firebaseio.com",
//   projectId: "react-firebase-authentic-b43cd",
//   storageBucket: "react-firebase-authentic-b43cd.appspot.com",
//   messagingSenderId: "502482847019"
// };

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const config = {
  apiKey: process.env.REACT_APP_G_API_KEY,
  authDomain: process.env.REACT_APP_G_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_G_DATABASE_URL,
  projectId: process.env.REACT_APP_G_PROJECTID,
  storageBucket: process.env.REACT_APP_G_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_G_MESSAGING_SENDERID,
  appId: process.env.REACT_APP_G_APPID,
  measurementId: process.env.REACT_APP_G_MEASUREMENTID
};

if (!firebase.apps.length) {
  //initializing with the config object
  firebase.initializeApp(config);
}

//separting database API and authentication
const db = firebase.database();
const auth = firebase.auth();

const facebookProvider = new firebase.auth.FacebookAuthProvider();

export { db, auth, facebookProvider };
