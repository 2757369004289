import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from '@material-ui/core/styles'
import Container from "@material-ui/core/Container";
import withAuthorization from "./withAuthorization";
import "firebase/auth";
import firebase from "firebase/app";
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import FALLBACK_IMAGE from './sentiments-site.png';
const Airtable = require("airtable");


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// function PositionedSnackbar() {
//     const [state, setState] = React.useState({
//         open: false,
//         vertical: 'top',
//         horizontal: 'center',
//     });
// }

function getDim(strIn) {
    const width = { windowWidth: window.innerWidth };
    const height = { windowWidth: window.innerHeight };
    const widthVal = width.windowWidth;
    const heightVal = height.windowWidth;
    console.log(heightVal);
    if (strIn === "width") {
        if (widthVal >= 600) {
            return widthVal;
        } else if (widthVal < 600) {
            return widthVal
        }
    } else if (strIn === "height") {
        if (heightVal >= 600) {
            return heightVal;
        } else if (heightVal < 600) {
            return heightVal;
        }
    }
}

const iframe = `<iframe src="https://coda.io/embed/83ot2xaWQi/_suHMC?viewMode=embedplay" width=${getDim('width')} height=${getDim('height')} style="max-width: 100%;" allow="fullscreen"></iframe>`;

function Iframe(props) {
    return (
        <div style={{overflow:"scroll"}}
            dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
        />
    );
}


const ProjectsPage = () => {
    const classes = useStyles()
    const user = firebase.auth().currentUser;
    const userEmail = user.email;
    const defaultEmail = 'default';
    const [cleanedDashesStr, setCleanedDashesStr] = useState(null)
    const [dashboardArr, setDashboardArr] = useState(null)

    const [open, setOpen] = React.useState(false);
    const [singleDashboardItem, setSingleDashboardItem] = useState(null);
    const [writeup, setWriteup] = useState(null);



    const handleClose = () => {
        setOpen(false);
    };


    // Airtable
    // const Airtable = require("airtable");
    // const base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE }).base(
    //     process.env.REACT_APP_AIRTABLE_BASE_KEY
    // );

        Airtable.configure({
            endpointUrl: 'https://api.airtable.com',
            apiKey: process.env.REACT_APP_AIRTABLE
        });
        var base = Airtable.base(process.env.REACT_APP_AIRTABLE_BASE_KEY);

    // Get rows for the account needed
    function getAirtableAccount() {

        console.log(userEmail)
        console.log(defaultEmail)
        base("Accounts")
            .select({
                view: "Grid view",
                filterByFormula: `OR({UserEmail} = '${userEmail}', {UserEmail} = '${defaultEmail}')`,
            })
            .firstPage(function (err, records) {
                if (err) {
                    console.error(err);
                    return;
                }
                const dashesNeeded = records[records.length - 1].fields.Dashboards;
                const cleanedDashesArr = dashesNeeded.map(
                    (i) => "RECORD_ID() = '" + i + "'"
                );
                setCleanedDashesStr("OR(" + cleanedDashesArr.join(", ") + ")");
            });
    }

    useEffect(() => {
        getAirtableAccount();
        console.log(cleanedDashesStr)
    }, [])


    function getAirtableDashboards(dashesStrIn) {
        if (!dashesStrIn) return
        // Get dashboards matching the account
        base("Dashboards")
            .select({
                view: "Grid view",
                filterByFormula: dashesStrIn,
            })
            .firstPage(function (err, records) {
                if (err) {
                    console.error(err);
                    return;
                }
                setDashboardArr(records)
            });
    }

    useEffect(() => {
        if (cleanedDashesStr) {
            getAirtableDashboards(cleanedDashesStr);
        }
    }, [cleanedDashesStr])

    if (dashboardArr) {
        console.log(dashboardArr)
    }

    // console.log(dashboardArr[0]._rawJson.fields.TableauEmbedCode)
    function DocumentBoxesNull(props) {
        return <p><i>Loading documents...</i></p>;
    }

    const ModalPopup = (props) => {

        const { tableau } = window;
        // const [openSnack, setOpenSnack] = useState(false)
        // useEffect(() => {
        //     setOpenSnack(true)
        // }, [])
        //
        // const handleSnackClose = () => {
        //     setOpenSnack(false)
        // };

        function TableauEmbed() {
            // if (singleDashboardItem) {
            const ref = useRef(null)
            const url = singleDashboardItem.dashItem.fields.TableauEmbedCode
            const urlClean = url.split('?:language=')[0]

            console.log(urlClean)

            function initViz() {
                const width = { windowWidth: window.innerWidth };
                const height = { windowWidth: window.innerHeight };
                const widthVal = width.windowWidth - 20;
                const heightVal = height.windowWidth - 30

                console.log(document.getElementById("dashboard").clientWidth)
                const options = {
                    device: "desktop",
                    hideTabs: false,
                    // height: "100vh",
                    // width: "100%"
                    // width: widthVal,
                    // width: document.getElementById("dashboard").clientWidth,
                    // height: window.innerHeight - document.getElementById("toolbar").clientHeight
                }

                console.log(ref.current)
                console.log(urlClean)
                
                return new tableau.Viz(ref.current, urlClean, options)

            }

            useEffect(() => {
                let result = initViz()
                
                window.addEventListener('resize', function () {
                    setTimeout(function () {
                        console.log(open)
                        if (open === true) {
                            result.setFrameSize(window.innerWidth, window.innerHeight - 65);
                        }
                    }, 100)
                });
            }, [])

            return (
                <div>
                    <div id='tabdash' ref={ref} />
                    <p style={{float:"right", fontSize:"11px", paddingRight:"8px"}} >If the window is not full screen or scrolling, click the fullscreen button in the bottom right. ⬆</p>
                </div>
            )
        }

        function CodaEmbed() {
            const url = writeup.dashItem.fields.Coda
            console.log(url)

            function getDim(strIn) {
                const width = { windowWidth: window.innerWidth };
                const height = { windowWidth: window.innerHeight };
                const widthVal = width.windowWidth;
                const heightVal = height.windowWidth;
                console.log(heightVal);
                if (strIn === "width") {
                    if (widthVal >= 600) {
                        return widthVal;
                    } else if (widthVal < 600) {
                        return widthVal
                    }
                } else if (strIn === "height") {
                    if (heightVal >= 600) {
                        return heightVal;
                    } else if (heightVal < 600) {
                        return heightVal;
                    }
                }
            }

            const iframe = `<iframe src=${url} width=${getDim('width')} height=${getDim('height')} style="max-width: 100%;" allow="fullscreen"></iframe>`;

            function Iframe(props) {
                return (
                    <div
                        dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
                    />
                );
            }

            return (<Iframe iframe={iframe} />)
        }






        if (!singleDashboardItem) {
            if (!writeup) {
                return <p></p>;
            } else {
                return (
                    <Dialog id={"dashboard"} fullScreen open={open} onClose={handleClose}
                        TransitionComponent={Transition}>
                        <AppBar style={{height : '40px', minHeight:'0px' }} className={classes.appBar}>
                            <Toolbar style={{height : '40px', minHeight:'0px' }} id={"toolbar"} className="toolbarModal">
                                <IconButton style={{padding:"0px", width:"16px", height:"16px"}} className="closeSVG" edge="start" color="inherit" onClick={handleClose}
                                    aria-label="close">
                                    <CloseIcon style={{padding:"0px", width:"16px", height:"16px"}} className="closeSVG"/>
                                </IconButton>
                                <Typography className={classes.title} >
                                    {writeup.dashItem.fields.DashboardTitle}
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <CodaEmbed urlIn={writeup.dashItem.fields.Coda}></CodaEmbed>
                    </Dialog>
                )
            }
        } else {
            return (
                <Dialog id={"dashboard"} fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                    <AppBar style={{height : '40px', minHeight:'0px' }} className={classes.appBar}>
                        <Toolbar style={{height : '40px', minHeight:'0px' }} id={"toolbar"} className="toolbarModal">
                            <IconButton style={{padding:"0px", width:"16px", height:"16px"}} className="closeSVG"  edge="start" color="inherit" onClick={handleClose}
                                aria-label="close">
                                <CloseIcon style={{padding:"0px", width:"16px", height:"16px"}}  className="closeSVG"/>
                            </IconButton>
                            {/*<p className={classes.title} style={{margin: 0}}>*/}
                            {/*    {singleDashboardItem.dashItem.fields.DashType}*/}
                            {/*</p>*/}
                            <Typography variant="h5" className={classes.title}>
                                {singleDashboardItem.dashItem.fields.DashboardTitle}
                            </Typography>
                            {/*<Button autoFocus color="inherit" onClick={handleClose}>*/}
                            {/*    Exit*/}
                            {/*</Button>*/}
                        </Toolbar>
                    </AppBar>
                    <TableauEmbed className={classes.dashboard}
                        urlIn={singleDashboardItem.dashItem.fields.TableauEmbedCode} />
                </Dialog>
            )
        }
    }

    function DocumentBoxesExists(props) {
        let value1 = false;
        return (
            <div className={classes.root}>
                <Grid container direction='row' spacing={2}>
                    {dashboardArr.map((dashItem, index) => (
                        <Grid item md={6} key={index} style={{ padding: "1.5em 1.5em" }}>
                            <Card className={classes.card}>
                                <CardMedia
                                    style={{ minHeight: 40 }}
                                    className={classes.media}
                                    image={dashItem.fields.Image ? dashItem.fields.Image[0].url : FALLBACK_IMAGE}
                                    title='React' />
                                <CardContent className={classes.titleBar}>
                                    <div style={{ maxWidth: "70%" }}>
                                        <Typography variant='h6' component='h2' style={{ lineHeight:1}}>
                                            {dashItem.fields.DashboardTitle}
                                        </Typography>
                                        <Typography className={classes.subtitle} variant='body2' component='p' style={{lineHeight: 1, marginTop: 3}}>
                                            {dashItem.fields.DashType}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Button className={classes.button} onClick={() => {
                                            setOpen(true)
                                            setSingleDashboardItem({ dashItem })
                                        }}>Dashboard</Button>
                                        {dashItem.fields.Coda !== undefined
                                            ? <Button className={classes.button} onClick={() => {
                                                setOpen(true)
                                                setSingleDashboardItem(null)
                                                setWriteup({ dashItem })
                                            }}>Doc</Button>
                                            : <div />}
                                    </div>
                                </CardContent>
                                <CardContent style={{display: "contents"}}>
                                    <Typography paragraph variant='body2' style={{padding:15, marginBottom: "unset"}}>
                                        {dashItem.fields.Description ? ((dashItem.fields.Description).length > 150
                                            ? dashItem.fields.Description.slice(0, 150) + `...`
                                            : dashItem.fields.Description) : ""}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                    <ModalPopup />
                </Grid>
            </div>);
    }

    function DocumentBoxesReturn(props) {
        if (dashboardArr) {
            return <DocumentBoxesExists />;
        }
        return <DocumentBoxesNull />;
    }

    // function initViz() {
    //     var containerDiv = document.getElementById("vizContainer"),
    //         url = "http://public.tableau.com/views/RegionalSampleWorkbook/Storms";
    //
    //     var viz = new tableau.Viz(containerDiv, url);
    // }

    return (
        <Container style={{ width: "100%", marginTop: "2em" }}>
            {/*<div>*/}
            {/*    /!*<h1>Your Documents</h1>*!/*/}
            {/*    /!*<p style={{marginTop: "5px", marginLeft: "5px"}}>*!/*/}
            {/*    /!*    This page houses your dashboards and reports.*!/*/}
            {/*    /!*</p>*!/*/}
            {/*</div>*/}

            {/*<initViz style={{width:800, height:700}}/>*/}

            <div>
                <DocumentBoxesReturn />
            </div>
        </Container>
    );
}
    ;

const useStyles = makeStyles(theme => ({
    overrides: {
        MuiToolbar: {
          regular: {
            backgroundColor: "#ffff00",
            color: "#000000",
            height: "32px",
            minHeight: "32px",
            '@media(min-width:600px)' : {
              minHeight:"40px",
              height: "40px"
            }
          }
        }
      },

    root: {
        flexGrow: 1,
        // overflow: "scroll"
        // margin: 20
    },
    MuiIconButton: {
        root: {
            padding: "1px"
        }
    },
    closeSVG: {
        height: "16px",
        width: "16px",
        padding: "0px"
    },
    toolbarModal: {
        backgroundColor: "#1b2430",
        height: "40px",
        minHeight: "0px"
    },
    MuiToolbar: {
        regular: {
          height: "40px",
          minHeight: "32px",
          '@media (min-width: 600px)': {
            minHeight: "40px"
          }
        },
    },
    gutterTopAndBottom: {
        margin: "5px"
    },
    card: {
        width: "100%",
        maxWidth: "100%",
        boxShadow: '0 .125rem .25rem rgba(0,0,0,.15)'
    },
    media: {
        height: 300,
        width: "100%"
    },
    appBar: {
        position: 'relative',
        backgroundColor: "#2A2A39",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        fontSize: "0.9em"
    },
    dashboard: {},
    titleBar: {
        backgroundColor: "#2A2A39",
        color: "white",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        paddingTop: 0,
        marginTop: 0,
        color: "#b6c0cb",
    },
    button: {
        background: '#17B81C',
        border: 0,
        borderRadius: 5,
        boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',
        color: 'white',
        height: "100%",
        minHeight: 36,
        padding: '5px 15px',
        marginLeft: '10px',
        '&:hover': {
            background: '#00d107',
            boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',
        },
    },

}))
const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(ProjectsPage); //grants authorization to open endpoint if an user is signed in
