import React, {Component} from "react";
import {Link} from "react-router-dom";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import './CustomStyling.css';

import {auth} from "../firebase";
import * as routes from "../constants/routes";
import AuthUserContext from "./AuthUserContext";
import {makeStyles} from "@material-ui/core/styles";
// import backImage from "./background1.jpg";
//it resets your password. It doesn’t matter if you are authenticated or not

const useStyles = makeStyles({
  root: {
    // backgroundImage: `url(${backImage})`,
    backgroundSize: "cover",
    height: "91vh",
    minWidth: 400,
    backgroundColor: "white",
    textAlign: "center",
  },
  boxInner: {
    padding: 20,
  },
  padTop: {
    marginTop: 300,
  },
  titleContainer: {
    marginTop: 20,
  },
  largeTitle: {
    color: "white",
    textAlign: "center",
    fontSize: '24px',
    fontWeight: 300,
  },
  smallTitle: {
    fontWeight: 400,
    textTransform: 'uppercase',
    letterSpacing: '.05em',
    fontSize: '1rem',
    textAlign: "center",
    color: "#17B81C",
  },
  button: {
    background: '#17B81C',
    border: 0,
    borderRadius: 5,
    boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',
    color: 'white',
    height: 36,
    padding: '0 15px',
    '&:hover': {
      background: '#00d107',
      boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',
    },
  },
});

const PasswordForgetPage = () => {
  const classes = useStyles();
  return (
      <div className={classes.root}>
        {/*<Card className={classes.root} variant="outlined">*/}
        {/*    <CardContent>*/}
        <div className={classes.boxInner}>
          <div className={classes.titleContainer}>
            <h1 className={classes.smallTitle}>PASSWORD RESET</h1>
            <h1 className={classes.largeTitle}>Enter your e-mail address to reset your password.</h1>
          </div>
          <PasswordForgetForm/>
          {/*<SignUpLink/>*/}
        </div>
        {/*    </CardContent>*/}
        {/*</Card>*/}
      </div>
  )
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value
});

//################### PasswordForget Form ###################
const INITIAL_STATE = {
  email: "",
  error: null,
  showingAlert: false
};

class PasswordForgetForm extends Component {
  state = { ...INITIAL_STATE };

  onSubmit = event => {
    const { email } = this.state;

    auth
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        document.getElementById('confirmation').innerText = "An e-mail has been sent containing instructions to reset your password."
      })
      .catch(error => {
        this.setState(byPropKey("error", error));
        this.timer(); //show alert message for some seconds
      });

    event.preventDefault();
  };

  timer = () => {
    this.setState({
      showingAlert: true
    });

    setTimeout(() => {
      this.setState({
        showingAlert: false
      });
    }, 4000);
  };

  render() {
    const { email, error, showingAlert } = this.state;

    const isInvalid = email === "";

    return (
      <div>
        {showingAlert && (
            <div style={{fontWeight: 400,
              textTransform: 'uppercase',
              letterSpacing: '.05em',
              fontSize: '1rem',
              textAlign: "center",
              paddingTop: '10px',
              color: 'red'
            }} onLoad={this.timer}>
              {error.message}
            </div>
        )}

        <form style={{padding:"20px 15% 0px 15%"}} onSubmit={this.onSubmit}>
            {/* <h4 for="exampleEmail">Email</h4> */}
            <AuthUserContext.Consumer>
    {authUser => (
      <div className="padText">
            <TextField
              type="email"
              name="email"
              id="exampleEmail"
              label="E-mail Address"
              fullWidth
              value={email}
              InputLabelProps={{
                style: { color: 'rgb(256,256,256,.75)',padding: "0px 0px 0px 10px", verticalAlign:"center", borderBottomColor: "green"},
              }}
              InputProps={{
                style: { color: 'white', padding: "5px 10px 5px 10px"},
              }}
              style={{backgroundColor:"rgba(256, 256, 256, .05)", margin: '0px 0px 15px 0px'}}
              onChange={event =>
                this.setState(byPropKey("email", event.target.value))
              }
            />
            </div>
    )}</AuthUserContext.Consumer>

          <div className="text-center padText">
            {isInvalid
                ? <Button style={{
                  background: 'rgb(23, 184, 28,.2)',
                  color: 'rgb(256,256,256,.2)',
                  border: 0,
                  borderRadius: 5,
                  height: 36,
                  padding: '0 15px',
                  width: "100%",
                }}
                          variant="contained"
                          type="submit"
                          disabled={isInvalid}>
                  Submit
                </Button>
                : <Button fullWidth style={{background: '#17B81C',
                  border: 0,
                  borderRadius: 5,
                  boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',
                  color: 'white',
                  height: 36,
                  padding: '0 15px',
                  width: "100%",
                  '&:hover': {
                    background: '#00d107',
                    boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',
                  },
                  '&:disabled': {
                    background: 'red',
                    boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',
                  },}}
                          variant="contained"
                          type="submit"
                          disabled={isInvalid}>
                  Submit
                </Button>}
          </div>
        </form>
        <br/>
        <div id={'confirmation'} style={{fontWeight: 400,
        textTransform: 'uppercase',
        letterSpacing: '.05em',
        fontSize: '0.80rem',
        textAlign: "center",
        paddingTop: '10px',
        color: 'white'
        }}> </div>
      </div>
    );
  }
}

//################### PasswordForget Link ###################
const PasswordForgetLink = () => (
  <p>
    <Link style={{fontSize:"14px", textDecoration: "none", textTransform: "uppercase", color: "#17B81C",}} to={routes.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

export default PasswordForgetPage;

export { PasswordForgetForm, PasswordForgetLink };

// <form onSubmit={this.onSubmit}>
//   <input
//     value={this.state.email}
//     onChange={event =>
//       this.setState(byPropKey("email", event.target.value))
//     }
//     type="text"
//     placeholder="Email Address"
//   />
//   <button disabled={isInvalid} type="submit">
//     Reset My Password
//   </button>

//   {error && <p>{error.message}</p>}
// </form>
