import React, {Component} from "react";

import {makeStyles} from "@material-ui/core/styles";
import {Link, withRouter} from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";

import * as routes from "../constants/routes";
import {auth, db} from "../firebase";

import "./CustomStyling.css";
// import backImage from "./background1.jpg";

const useStyles = makeStyles({
  root: {
    // backgroundImage: `url(${backImage})`,
    backgroundSize: "cover",
    minHeight: "100vh",
    width: "100%",
    position: "relative",
    // minWidth: 400,
    backgroundColor: "white",
    textAlign: "center",
  },
  boxInner: {
    padding: "1em",
  },
  padTop: {
    marginTop: 300,
  },
  titleContainer: {
    marginTop: 20,
  },
  largeTitle: {
    color: "#333",
    textAlign: "center",
    fontSize: '24px',
    fontWeight: 300,
  },
  smallTitle: {
    fontWeight: 400,
    textTransform: 'uppercase',
    letterSpacing: '.05em',
    fontSize: '1rem',
    textAlign: "center",
    color: "#333",
  },
  button: {
    background: '#17B81C',
    border: 0,
    borderRadius: 5,
    boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',
    color: 'white',
    height: 36,
    padding: '0 15px',
    '&:hover': {
      background: '#00d107',
      boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',
    },
  },
});

const SignUpPage = ({ history }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.boxInner}>
        <div className={classes.titleContainer}>
          <h1 className={classes.smallTitle}>Dashboard</h1>
          <h1 className={classes.largeTitle}>Sign up to view your data and projects, etc.</h1>
        </div>
        <SignUpForm history={history} />
      </div>
    </div>
  );
};

//################### Sign Up Form ###################
const INITIAL_STATE = {
  username: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  error: null,
  showingAlert: false,
  button1: false,
  button2: false
};

//A Higher order function with prop name as key and the value to be assigned to
const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});


class SignUpForm extends Component {
  //defining state
  state = {
    ...INITIAL_STATE,
  };

  // onChange = (propName, value) => {
  //   this.setState({
  //     [propName]: value
  //   });
  // };

  onSubmit = (event) => {
    const { username, email, passwordOne } = this.state;
    const { history } = this.props;
    auth
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      //it the above functions resolves, reset the state to its initial state values, otherwise, set the error object
      .then((authUser) => {
        //creating a user in the database after the sign up through Firebase auth API
        db.doCreateUser(authUser.user.uid, username, email)
          .then(() => {
            this.setState({
              ...INITIAL_STATE,
            });
            history.push(routes.PROJECTS); //redirects to Home Page
          })
          .catch((error) => {
            this.setState(byPropKey("error", error));
            this.timer(); //show alert message for some seconds
          });
      })
      .catch((err) => {
        this.setState(byPropKey("error", err));
        this.timer(); //show alert message for some seconds
      });

    event.preventDefault(); //prevents refreshing
  };

  timer = () => {
    this.setState({
      showingAlert: true,
    });

    setTimeout(() => {
      this.setState({
        showingAlert: false,
      });
    }, 4000);
  };

  render() {
    const { username, email, passwordOne, passwordTwo, error, showingAlert, button1, button2 } =
      this.state;
    //a boolen to perform validation
    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === "" ||
      email === "" ||
      username === "" ||
      button1 === true ||
      button2 === false;

      console.log(isInvalid)



    return (
      <div>
        {showingAlert && (
            <div style={{fontWeight: 400,
              textTransform: 'uppercase',
              letterSpacing: '.05em',
              fontSize: '1rem',
              textAlign: "center",
              paddingTop: '10px',
              color: 'red'
            }} onLoad={this.timer}>
              {error.message}
            </div>
        )}
        <form style={{borderColor:"green",padding:"20px 15% 0px 15%"}} onSubmit={this.onSubmit}>
          <TextField
            type="username"
            name="username"
            id="userName"
            label="Username"
            fullWidth
            value={username}
            InputLabelProps={{
              style: { color: '#333',padding: "0px 0px 0px 10px", verticalAlign:"center", borderBottomColor: "green"},
            }}
            InputProps={{
              style: { color: '#333', padding: "5px 10px 5px 10px"},
            }}
            style={{backgroundColor:"rgba(256, 256, 256, .05)", margin: '0px 0px 15px 0px'}}
            onChange={(e) =>
              this.setState(byPropKey("username", e.target.value))
            }
          />
          <TextField
            type="email"
            name="email"
            id="exampleEmail"
            label="E-mail Address"
            fullWidth
            value={email}
            InputLabelProps={{
              style: { color: '#333',padding: "0px 0px 0px 10px", verticalAlign:"center", borderBottomColor: "green"},
            }}
            InputProps={{
              style: { color: '#333', padding: "5px 10px 5px 10px"},
            }}
            style={{backgroundColor:"rgba(256, 256, 256, .05)", margin: '0px 0px 15px 0px'}}
            onChange={(e) => this.setState(byPropKey("email", e.target.value))}
          />
          <TextField
            type="password"
            name="password"
            id="examplePassword1"
            label="Password"
            fullWidth
            value={passwordOne}
            InputLabelProps={{
              style: { color: '#333',padding: "0px 0px 0px 10px", verticalAlign:"center", borderBottomColor: "green"},
            }}
            InputProps={{
              style: { color: '#333', padding: "5px 10px 5px 10px"},
            }}
            style={{backgroundColor:"rgba(256, 256, 256, .05)", margin: '0px 0px 15px 0px'}}
            onChange={(e) =>
              this.setState(byPropKey("passwordOne", e.target.value))
            }
          />
          <TextField
            type="password"
            name="password"
            id="examplePassword2"
            label="Confirm Password"
            fullWidth
            value={passwordTwo}
            InputLabelProps={{
              style: { color: '#333',padding: "0px 0px 0px 10px", verticalAlign:"center", borderBottomColor: "green"},
            }}
            InputProps={{
              style: { color: '#333', padding: "5px 10px 5px 10px"},
            }}
            style={{backgroundColor:"rgba(256, 256, 256, .05)", margin: '0px 0px 15px 0px'}}
            onChange={(e) =>
              this.setState(byPropKey("passwordTwo", e.target.value))
            }
          />

          <div
            style={{ display: "flex", alignItems: "center", justifyContent:"center", color:"#333" }}
          >
            <Checkbox
              id="termsConditionsButton1"
              color="primary"
              inputProps={{ "aria-label": "secondary checkbox" }}
              value={this.button1}
              onChange={(e) =>
                this.setState(byPropKey("button1", e.target.checked))  }
              defaultChecked={false}
              style={{ color: '#17B81C'}}
            />
            <p style={{ marginBottom: 0 }}>
              Leave this unchecked if you're human.
            </p>
          </div>

          <div
            style={{ display: "flex", alignItems: "center", justifyContent:"center", paddingBottom: 20, color:"#333"}}
          >
            <Checkbox
              id="termsConditionsButton2"
              color="primary"
              inputProps={{ "aria-label": "secondary checkbox", color:"white" }}
              value={this.button2}
              onChange={(e) =>
                this.setState(byPropKey("button2", e.target.checked))  }              
              defaultChecked={false}
              style={{ color: '#17B81C'}}
            />
            <p style={{ marginBottom: 0 }}>
              And check this if you are in fact, a human. {<br />}
              {/* (Occam's razor approach to bot detection 😂) */}
            </p>
          </div>

          <div className="text-center">
            {isInvalid
                ? <Button style={{
                  background: 'rgb(23, 184, 28,.2)',
                  color: 'rgb(256,256,256,.2)',
                  border: 0,
                  borderRadius: 5,
                  height: 36,
                  padding: '0 15px',
                  width: "100%",
                }}
                          variant="contained"
                          type="submit"
                          disabled={isInvalid}>
                  Sign Up
                </Button>
                : <Button fullWidth style={{background: '#17B81C',
                  border: 0,
                  borderRadius: 5,
                  boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',
                  color: 'white',
                  height: 36,
                  padding: '0 15px',
                  width: "100%",
                  '&:hover': {
                    background: '#45C649',
                    boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',
                  },
                  '&:disabled': {
                    background: 'red',
                    boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',
                  },}}
                          variant="contained"
                          type="submit"
                          disabled={isInvalid}>
                  Sign Up
                </Button>}
          </div>
        </form>
      </div>
    );
  }
}

//################### Sign Up Link ###################
//used in the sign in when the user don't have an account registered yet
const SignUpLink = () => (
  <p style={{color: "white", fontSize: 16 }}>
    Don't have an account? <Link style={{textDecoration: "none", textTransform: "uppercase", color: "#17B81C",}} to={routes.SIGN_UP}>Sign Up</Link>
  </p>
);

//exports
export default withRouter(SignUpPage); //using a HoC to get access to history
export { SignUpForm, SignUpLink };

// <form onSubmit={this.onSubmit}>
//   <input
//     value={username}
//     onChange={e => this.setState(byPropKey("username", e.target.value))}
//     // onChange={e => this.onChange("username", e.target.value)}
//     type="text"
//     placeholder="Full Name"
//   />
//   <input
//     value={email}
//     onChange={e => this.setState(byPropKey("email", e.target.value))}
//     type="text"
//     placeholder="Email Address"
//   />
//   <input
//     value={passwordOne}
//     onChange={e =>
//       this.setState(byPropKey("passwordOne", e.target.value))
//     }
//     type="password"
//     placeholder="Password"
//   />
//   <input
//     value={passwordTwo}
//     onChange={e =>
//       this.setState(byPropKey("passwordTwo", e.target.value))
//     }
//     type="password"
//     placeholder="Confirm Password"
//   />
//   <button disabled={isInvalid} type="submit">
//     Sign Up
//   </button>

//   {error && <p>{error.message}</p>}
// </form>
