import React, {Component} from "react";
import {makeStyles} from '@material-ui/core/styles'
import withAuthorization from "./withAuthorization";
import axios from 'axios';
import "firebase/auth";
import Button from '@material-ui/core/Button'
import TextField from "@material-ui/core/TextField";
// import backImage from "./background1.jpg";

const useStyles = makeStyles({
    root: {
        // backgroundImage: `url(${backImage})`,
        backgroundSize: "cover",
        minHeight: "100vh",
        width: "100%",
        position: "fixed",
        backgroundColor: "white",
        textAlign: "center",
    },
    boxInner: {
        padding: "1em",
    },
    padTop: {
        marginTop: 300,
    },
    titleContainer: {
        marginTop: 20,
    },
    largeTitle: {
        color: "#333",
        textAlign: "center",
        fontSize: '24px',
        fontWeight: 300,
    },
    smallTitle: {
        fontWeight: 400,
        textTransform: 'uppercase',
        letterSpacing: '.05em',
        fontSize: '1rem',
        textAlign: "center",
        color: "#333",
    },
    button: {
        background: '#17B81C',
        border: 0,
        borderRadius: 5,
        boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',
        color: 'white',
        height: 36,
        padding: '0 15px',
        '&:hover': {
            background: '#00d107',
            boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',
        },
    },
});

const RequestsPage = ({history}) => {
    const classes = useStyles();
    return (
        <div className={classes.root} style={{textAlign:"-webkit-center"}}>
            <div className={classes.boxInner} style={{maxWidth:1200}}>
                <div className={classes.titleContainer}>
                    <h1 className={classes.smallTitle}>New Project Coming Up?</h1>
                    <p style={{maxWidth: "90%", fontSize: 12}}>Send us the details and set a time to brainstorm how to best leverage data in your project.</p>
                    {/*<p>Or shoot us an email </p>*/}
                </div>
                <RequestsForm style={{textAlign: "center"}} history={history}/>
            </div>
        </div>
    )
}

class RequestsForm extends Component {

    // https://onlyoneaman.medium.com/link-your-form-with-google-form-for-responses-7a408dafb8f8
    // record what we're writing in each form input
    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    // make post request for g forms
    gFormsRequest = async (url) => {
        let response;
        try {
            response = await axios.post(url, null, null)
            if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
                console.log('Successfully sent');
            }
        } catch (e) {
            response = e;
            console.log('Failed send')
        }
        console.log('response here:')
        console.log(response)
    }


    handleSubmit = async (event) => {
        event.preventDefault()

        const data = {
            ...this.state
        }
        const id = "1FAIpQLScj_OJfyMcn09C-zvWqlCAFR1DmF2uGfKQS1bG7nsc4tOIC9g" //YOUR FORM ID

        const formUrl = "https://docs.google.com/forms/d/e/" + id + "/formResponse";
        const queryString = require('query-string')
        const q = queryString.stringifyUrl({
            url: formUrl,
            query: data
        })
        this.gFormsRequest(q)
        console.log(q)
        alert("Thank you for submitting a project request. Our team will send you an email shortly.")
    }


    render() {
        return (
                <div style={{flexDirection: "column"}}>
                    <form style={{borderColor: "green", padding: "20px 5% 0px 5%", maxWidth: 900}}
                        // action={"https://docs.google.com/forms/d/e/1FAIpQLScj_OJfyMcn09C-zvWqlCAFR1DmF2uGfKQS1bG7nsc4tOIC9g/formResponse"}
                        // method={"post"}
                        // if user presses enter, nothing happens
                          onKeyPress={event => {
                              if (event.which === 13 /* Enter */) {
                                  event.preventDefault();
                              }
                          }}
                          onSubmit={this.handleSubmit}
                        // target={"hidden_iframe"}
                    >
                        <div>
                            <TextField
                                type="text"
                                name="entry.302771541"
                                label="Your name"
                                fullWidth
                                onChange={this.handleChange}
                                InputLabelProps={{
                                    style: {
                                        color: '#333',
                                        padding: "0px 0px 0px 5px",
                                        verticalAlign: "center",
                                        borderBottomColor: "green"
                                    },
                                }}
                                InputProps={{
                                    style: {color: '#333'},
                                }}
                                style={{backgroundColor: "rgba(256, 256, 256, .05)", margin: '0px 0px 15px 0px', minHeight:58}}
                            />
                            <TextField
                                type="text"
                                name="entry.368509010"
                                label="Email address"
                                fullWidth
                                onChange={this.handleChange}
                                InputLabelProps={{
                                    style: {
                                        color: '#333',
                                        padding: "0px 0px 0px 5px",
                                        verticalAlign: "center",
                                        borderBottomColor: "green"
                                    },
                                }}
                                InputProps={{
                                    style: {color: '#333'},
                                }}
                                style={{backgroundColor: "rgba(256, 256, 256, .05)", margin: '0px 0px 15px 0px', minHeight:58}}
                            />

                            <TextField
                                type="text"
                                name="entry.1490401970"
                                label="Geographic region of your study"
                                fullWidth
                                onChange={this.handleChange}
                                InputLabelProps={{
                                    style: {
                                        color: '#333',
                                        padding: "0px 0px 0px 5px",
                                        verticalAlign: "center",
                                        borderBottomColor: "green"
                                    },
                                }}
                                InputProps={{
                                    style: {color: '#333'},
                                }}
                                style={{backgroundColor: "rgba(256, 256, 256, .05)", margin: '0px 0px 15px 0px', minHeight:58}}
                            />

                            <TextField
                                type="text"
                                name="entry.931177475"
                                label="Variables you'll want to collect."
                                fullWidth
                                multiline
                                onChange={this.handleChange}
                                InputLabelProps={{
                                    style: {
                                        color: '#333',
                                        padding: "0px 0px 0px 5px",
                                        verticalAlign: "center",
                                        borderBottomColor: "green"
                                    },
                                }}
                                InputProps={{
                                    style: {color: '#333'},
                                }}
                                style={{backgroundColor: "rgba(256, 256, 256, .05)", margin: '0px 0px 15px 0px', minHeight:58}}
                            />
                        </div>
                        <Button
                            type={"submit"}

                            fullWidth style={{
                            background: '#17B81C',
                            border: 0,
                            borderRadius: 5,
                            boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',
                            color: 'white',
                            height: 36,
                            padding: '0 15px',
                            width: "100%",
                            maxWidth: 100,
                            '&:hover': {
                                background: '#00d107',
                                boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',
                            },
                            '&:disabled': {
                                background: 'red',
                                boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',
                            },
                        }}
                            variant="contained"
                            // type="submit"
                        >
                            Submit
                        </Button>
                    </form>
                </div>
        );
    }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(RequestsPage); //grants authorization to open endpoint if an user is signed in
