import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';

import "./CustomStyling.css";

import { withRouter } from "react-router-dom";
import { PasswordForgetLink } from "./PasswordForget";
import { auth } from "../firebase";
import * as routes from "../constants/routes";
import sauImg from ".././files/kepler 2.png";

const useStyles = makeStyles({
    root: {
        // backgroundImage: `url(${backImage})`,
        // backgroundSize: "cover",
        // minHeight: "100vh",
        textAlign: "-webkit-center",
        margin: "auto",
        display: "flex",
        alignItems: "center",
        minHeight: "80vh",
        maxWidth: 1200,
        justifyContent: "center",
        width: "100%",
        // position: "fixed",
        backgroundColor: "white",
        textAlign: "-webkit-center",
        paddingTop: "30px",
        marginBottom: "70px"
    },
    boxInner: {
        padding: "0px 20px",
    },
    padTop: {
        marginTop: 300,
    },
    titleContainer: {
        marginTop: 20,
    },
    largeTitle: {
        color: "#333",
        textAlign: "center",
        fontSize: '24px',
        fontWeight: 300,
    },
    smallTitle: {
        fontWeight: 400,
        textTransform: 'uppercase',
        letterSpacing: '.05em',
        fontSize: '1rem',
        textAlign: "center",
        color: "#333",
    },
    button: {
        background: '#17B81C',
        border: 0,
        borderRadius: 5,
        boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',
        color: '#333',
        height: 36,
        padding: '0 15px',
        '&:hover': {
            background: '#00d107',
            boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',
        },
    },
    customTextField: {
        "& input::placeholder": {
            fontSize: "20px"
        }
    }
});

const SignInPage = ({ history }) => {
    const classes = useStyles();
    return (
        // <div className={classes.backgroundImage} style={{marginTop:"6em"}}>
        <div className={classes.root}>
            {/*<Card className={classes.root} variant="outlined">*/}
            {/*    <CardContent>*/}
            <Grid container
                spacing={0}
                align="center"
                // justify="center"
                justifyContent="flex-start"
            // direction="row"
            >
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} order={{ xs: 1, sm: 1, md: 2, lg: 2 }} style={{margin:"auto"}}>
                    <div className={classes.boxInner} >
                        <img src={sauImg} style={{ maxWidth: 600, width: "90%", height: "auto" }} />
                    </div>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} order={{ xs: 2, sm: 2, md: 1, lg: 1 }} style={{margin:"auto"}}>
                    <div >
                        <div className={classes.boxInner} style={{ maxWidth: 1200, }}>
                            <div className={classes.titleContainer}>
                                {/* <h1 className={classes.smallTitle}>Basil Labs</h1> */}
                                <h1 className={classes.smallTitle}>Log in to view your data & dashboards.</h1>
                            </div>
                            <div style={{ marginBottom: 10 }}>
                                <SignInForm style={{ textAlign: "center" }} history={history} />
                                {/*<SignUpLink/>*/}

                            </div>
                            <PasswordForgetLink />
                        </div>
                    </div>
                </Grid>

            </Grid>

            {/*    </CardContent>*/}
            {/*</Card>*/}
        </div>
        //</div>
    );
};

const byPropKey = (propertyName, value) => () => ({
    [propertyName]: value,
});

const INITIAL_STATE = {
    email: "",
    password: "",
    error: null,
    showingAlert: false,
};

class SignInForm extends Component {
    state = { ...INITIAL_STATE };


    onSubmit = (event) => {
        const { email, password } = this.state;

        const { history } = this.props;

        auth
            .doSignInWithEmailAndPassword(email, password)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
                history.push(routes.PROJECTS);
            })
            .catch((error) => {
                this.setState(byPropKey("error", error));
                this.timer(); //defined below
            });

        event.preventDefault();
    };

    timer = () => {
        this.setState({
            showingAlert: true,
        });

        // setTimeout(() => {
        //     this.setState({
        //         showingAlert: false,
        //     });
        // }, 4000);
    };

    render() {

        const { email, password, error, showingAlert } = this.state;

        const isInvalid = password === "" || email === "";

        return (
            <div style={{ textAlign: "center", flexDirection: "column" }}>
                {showingAlert && (
                    <div style={{
                        fontWeight: 400,
                        textTransform: 'uppercase',
                        letterSpacing: '.05em',
                        fontSize: '1rem',
                        textAlign: "center",
                        paddingTop: '10px',
                        color: 'red'
                    }} onLoad={this.timer}>
                        {error.message}
                    </div>
                )}

                <form style={{ borderColor: "green", padding: "20px 15% 0px 15%" }} onSubmit={this.onSubmit}>
                    {/*<h4 className="padText" htmlFor="exampleEmail">*/}
                    {/*    Email*/}
                    {/*</h4>*/}
                    <TextField
                        type="email"
                        name="email"
                        label="E-mail Address"
                        fullWidth
                        InputLabelProps={{
                            style: { color: '#333', padding: "0px 0px 0px 10px", verticalAlign: "center", borderBottomColor: "green" },
                        }}
                        InputProps={{
                            style: { color: '#333', },
                        }}
                        value={email}
                        style={{ backgroundColor: "rgba(256, 256, 256, .05)", margin: '0px 0px 15px 0px' }}
                        onChange={(event) =>
                            this.setState(byPropKey("email", event.target.value))
                        }
                    />

                    {/*<h4 className="padText" htmlFor="examplePassword">*/}
                    {/*    Password*/}
                    {/*</h4>*/}
                    <TextField
                        type="password"
                        name="password"
                        label="Password"
                        fullWidth
                        value={password}
                        InputLabelProps={{
                            style: { color: '#333', padding: "0px 0px 0px 10px", verticalAlign: "center", borderBottomColor: "green" },
                        }}
                        InputProps={{
                            style: { color: '#333' },
                        }}
                        style={{ backgroundColor: "rgba(256, 256, 256, .05)", margin: '0px 0px 15px 0px' }}
                        onChange={(event) =>
                            this.setState(byPropKey("password", event.target.value))
                        }
                    />

                    {isInvalid
                        ? <Button style={{
                            background: 'rgb(23, 184, 28,.8)',
                            color: 'rgb(256,256,256,1)',
                            border: 0,
                            borderRadius: 5,
                            height: 36,
                            padding: '0 15px',
                            width: "100%",
                        }}
                            variant="contained"
                            type="submit"
                            disabled={isInvalid}>
                            Log In
                        </Button>
                        : <Button fullWidth style={{
                            background: '#17B81C',
                            border: 0,
                            borderRadius: 5,
                            boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',
                            color: '#333',
                            height: 36,
                            padding: '0 15px',
                            width: "100%",
                            '&:hover': {
                                background: '#00d107',
                                boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',
                            },
                            '&:disabled': {
                                background: 'red',
                                boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',
                            },
                        }}
                            variant="contained"
                            type="submit"
                            disabled={isInvalid}>
                            Log In
                        </Button>}
                    {/*<Button style={{background: '#17B81C',*/}
                    {/*    border: 0,*/}
                    {/*    borderRadius: 5,*/}
                    {/*    boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',*/}
                    {/*    color: 'white',*/}
                    {/*    height: 36,*/}
                    {/*    padding: '0 15px',*/}
                    {/*    '&:hover': {*/}
                    {/*        background: '#00d107',*/}
                    {/*        boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',*/}
                    {/*    },*/}
                    {/*    '&:disabled': {*/}
                    {/*        background: 'red',*/}
                    {/*        boxShadow: '0 0px 0px 0px rgba(0, 0, 0, .3)',*/}
                    {/*    },}}*/}
                    {/*        variant="contained"*/}
                    {/*        type="submit"*/}
                    {/*        disabled={isInvalid}>*/}
                    {/*    Log In*/}
                    {/*</Button>*/}
                </form>

                {/* <hr /> */}
            </div>
        );
    }
}

//export default withRouter(withStyles(styles)(SignInPage));
export default withRouter(SignInPage);

export { SignInForm };
